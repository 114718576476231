import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

import '../../generic-page.scss';

const CSGuidesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_guides.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Guides</h1>
          <h2>Our guides will help you become a better player.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Newbie zone" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Beginner guide"
            link="/counter-side/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_beginner.png"
                alt="Beginner guide"
              />
            }
          />
          <CategoryCard
            title="Intro to RTA concepts"
            link="/counter-side/guides/rta-concepts"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_pvphistory.png"
                alt="Siege Playbook"
              />
            }
          />
          <CategoryCard
            title="Shops and currencies"
            link="/counter-side/guides/shops-and-currencies"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_shops.png"
                alt="Shops and currencies"
              />
            }
          />
          <CategoryCard
            title="Spender guide"
            link="/counter-side/guides/spender-guide"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_spending.png"
                alt="Spender guide"
              />
            }
          />
          <CategoryCard
            title="Who to reroll for"
            link="/counter-side/guides/reroll"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_reroll.png"
                alt="Who to reroll for"
              />
            }
          />
        </Row>
        <SectionHeader title="General guides" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Game modes"
            link="/counter-side/guides/game-modes"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_gamemodes.png"
                alt="Game modes"
              />
            }
          />
          <CategoryCard
            title="Operators and you"
            link="/counter-side/guides/operators-and-you"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_operators.png"
                alt="Operators and you"
              />
            }
            updated
          />
          <CategoryCard
            title="Rearm system"
            link="/counter-side/guides/rearm-system"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_rearm.png"
                alt="Rearm system"
              />
            }
          />
          <CategoryCard
            title="Ship building cost"
            link="/counter-side/guides/ship-building-cost"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_shipcost.png"
                alt="Ship building cost"
              />
            }
          />
          <CategoryCard
            title="Ship building order"
            link="/counter-side/guides/ship-building-order"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_shipbuild.png"
                alt="Ship building order"
              />
            }
          />
          <CategoryCard
            title="Gremory's Bar"
            link="/counter-side/guides/gremory-bar"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_grem.png"
                alt="Gremory's Bar"
              />
            }
          />
          <CategoryCard
            title="Alternium Reactor"
            link="/counter-side/guides/alternium-reactor"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_reactor.png"
                alt="Alternium Reactor"
              />
            }
          />
        </Row>
        <SectionHeader title="Game mechanics" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Range and targeting"
            link="/counter-side/guides/range-and-targeting"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_range.png"
                alt="Range and targeting"
              />
            }
          />
          <CategoryCard
            title="Terms and mechanics"
            link="/counter-side/guides/terms-and-mechanics"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_terms.png"
                alt="Terms and mechanics"
              />
            }
          />
        </Row>
        <SectionHeader title="Gearing" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Basic gearing"
            link="/counter-side/guides/basic-gearing"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_gearing.png"
                alt="Beginner gearing"
              />
            }
          />
          <CategoryCard
            title="Advanced gearing"
            link="/counter-side/guides/advanced-gearing"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_gearbuilder.png"
                alt="Advanced gearing"
              />
            }
          />
          <CategoryCard
            title="Relic and latent gear"
            link="/counter-side/guides/relic-and-latent-gear"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_latent.png"
                alt="Relic and latent gear"
              />
            }
          />
          <CategoryCard
            title="PVE Gearing"
            link="/counter-side/guides/pve-gearing"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_pvpgearing.png"
                alt="PVE Gearing"
              />
            }
          />
          <CategoryCard
            title="PVP Gearing"
            link="/counter-side/guides/pvp-gearing"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_pvpgearing.png"
                alt="PVP Gearing"
              />
            }
          />
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297010"></div>
        <SectionHeader title="PVP guides" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Intro to RTA concepts"
            link="/counter-side/guides/rta-concepts"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_pvphistory.png"
                alt="Siege Playbook"
              />
            }
          />
          <CategoryCard
            title="Siege Playbook"
            link="/counter-side/guides/siege-playbook"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_siege.png"
                alt="Siege Playbook"
              />
            }
          />
          <CategoryCard
            title="Evolved-001 Playbook"
            link="/counter-side/guides/evolved-playbook"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_evo.png"
                alt="Evolved-001 Playbook"
              />
            }
          />
          <CategoryCard
            title="PVP Ban System explained"
            link="/counter-side/guides/pvp-bans"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_bans.jpg"
                alt="PVP Ban System explained"
              />
            }
          />
        </Row>
        <SectionHeader title="PVE guides" />
        <h5 className="section-break">Danger Close</h5>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Mode overview"
            link="/counter-side/guides/danger-close"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_dangerclose_overview.png"
                alt="Danger Close Overview"
              />
            }
            updated
          />
        </Row>
        <h5 className="section-break">Dimension Trimming</h5>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Dimension Trimming"
            link="/counter-side/guides/dimension-trimming"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_dimtrim.png"
                alt="Dimension Trimming"
              />
            }
          />
        </Row>
        <h5 className="section-break">Dive</h5>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Mode overview"
            link="/counter-side/guides/dive-introduction"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_diveintro.png"
                alt="Dive introduction"
              />
            }
          />
          <CategoryCard
            title="Dive 48 - 50 guide"
            link="/counter-side/guides/dive-49-50"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_dive.png"
                alt="Dive 48 and 50"
              />
            }
          />
          <CategoryCard
            title="Dive - 60"
            link="/counter-side/guides/dive-60"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_dive.png"
                alt="Dive 60"
              />
            }
          />
        </Row>
        <h5 className="section-break">Guild Coop</h5>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Mode overview"
            link="/counter-side/guides/guild-coop"
            image={
              <StaticImage
                src="../../../images/counterside/categories/icon_guild_raid.png"
                alt="Guild raid"
              />
            }
            updated
          />
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <h5 className="section-break">Raids</h5>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Raids - mode overview"
            link="/counter-side/guides/raids"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_raids.png"
                alt="Raids"
              />
            }
          />
          <CategoryCard
            title="Raids - Britra"
            link="/counter-side/guides/raid-britra"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_raidbritra.jpg"
                alt="Raids"
              />
            }
          />
          <CategoryCard
            title="Raids - Inhibitor"
            link="/counter-side/guides/raid-inhibitor"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_raidinhi.jpg"
                alt="Raids "
              />
            }
          />
          <CategoryCard
            title="Raids - Kraken"
            link="/counter-side/guides/raid-kraken"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_raidkraken.jpg"
                alt="Raids"
              />
            }
          />
        </Row>
        <h5 className="section-break">Shadow Palace</h5>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Mode overview"
            link="/counter-side/guides/shadow-palace"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_overview.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Rewards"
            link="/counter-side/guides/shadow-palace-rewards"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_rewards.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Shop guide"
            link="/counter-side/guides/shadow-palace-shop"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_shop.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Spectral gear"
            link="/counter-side/guides/shadow-palace-spectral"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_spectral.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Tips for all floors"
            link="/counter-side/guides/shadow-palace-tips"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_all.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 1"
            link="/counter-side/guides/shadow-palace-floor-1"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_1.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 2"
            link="/counter-side/guides/shadow-palace-floor-2"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_2.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 3"
            link="/counter-side/guides/shadow-palace-floor-3"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_3.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 4"
            link="/counter-side/guides/shadow-palace-floor-4"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_4.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 5"
            link="/counter-side/guides/shadow-palace-floor-5"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_5.png"
                alt="Shadow Palace"
              />
            }
          />
        </Row>
        {/* <h5 className="section-break">Relic Dungeons</h5>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Mode overview"
            link="/counter-side/guides/relic-dungeons"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_relicdungeons.png"
                alt="Relic Dungeons"
              />
            }
          />
        </Row> */}
      </div>
    </DashboardLayout>
  );
};

export default CSGuidesPage;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Counter Side | Prydwen Institute"
    description="Guides for Counter Side Global version."
    game="cs"
  />
);
